.nav__icon {
    color: black !important;
}

.nav-btn {
    position: absolute;
    top: 25px;
    right: 10px;
    color: black;
}

.logo {
    position: absolute;
    top: -5px;
    left: 25px;
    color: black;
}

.logo img {
    width: 100px;
    height: auto;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 2rem;
    z-index: 2;
    color: var(--textColor);
    box-shadow: 0px 5px 2.5px -5px #111;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

nav a {
    margin: 0 1rem;
    color: black;
    text-decoration: none;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #212135;
}

.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: black;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    margin-top: -20px;
}

header div,
nav {
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
    header {
        background: linear-gradient(rgba(255, 255, 255, 0.884), rgba(252, 252, 252, 0.979));
    }

    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 60%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColor);
        transition: 1s;
        transform: translateY(-100vh);
        background-color: rgb(255, 255, 255);
    }

    header .responsive_nav {
        transform: none;
    }

    nav .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a {
        font-size: 1.5rem;
    }
}