.feature{
    width: 100%;
    padding-top: 3.5vh;
    padding-bottom: 3.5vh;
}
.feature h1{
    font-size: 28px;
    color: #212135;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}
.feature__logo__collection{
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 70px auto;
}
.feature__logo__collection img{
    max-width: 160px;
    height: auto;
}
/* to make it responsive */
@media only screen and (max-width: 990px) {
    .feature{
        padding-top: 16.5vh;
        padding-left: 10px;
        padding-right: 10px;
    }
    .feature__logo__collection{
        max-width: 600px;
    }
   }
   @media only screen and (max-width: 600px) {
    .feature h1{
        padding-bottom: 25px;
        font-size: 24px;

    }
    .feature__logo__collection{
        flex-direction: column;
        max-width: 150px;
        margin: 30px auto;
    }
    .feature__logo__collection img{
        padding-bottom: 55px;
    }
   }