.service__back h2{
    font-size: 35px;
    font-weight: 500;
    color: black;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 5px;
   }
   .service__top__line{
     height: 2.5px;
     max-width: 190px;
     margin: 6px auto;
     background-color: yellow;
   }
   .service{
     z-index: 1;
     margin-top: 90px;
   }
   .service__second{
     margin-top: 40px ;
   }
   .service__page{
     display: flex;
     position: relative;
   }
   .service__pic{
     width: 100%;
     max-width: 700px;
   }
   .service__pic img{
     height: 100%;
     height: auto;
     max-height: 310px;
   }
   .service__empty{
     width: 100%;
     max-width: 700px;
   }
   .service__text{
     position: absolute;
     top: 40px;
     right: 16px;
     width: 100%;
     max-width: 700px;
     padding-top: 45px;
     padding-bottom: 45px;
     padding-left: 35px;
     background: linear-gradient(rgba(255, 255, 255, 0.884),rgba(252, 252, 252, 0.979));
     box-shadow: 0 -5px 5px -5px rgb(81, 81, 81), 
                 0 5px 5px -5px rgb(81, 81, 81);
   }
   .service__text h3{
     color: #212135;
   }
   .line{
     height: 2px; 
     width: 50px;
     background-color: yellow;
   }
   .service__text h3{
     padding-top: 20px;
     font-weight: 200;
   }
   .service__text p{
     padding-top: 10px;
     color: gray;
   }
   
   @media only screen and (max-width: 995px) {
   
     .service__second{
       margin-top: 350px;
     }
     .service__page{
       flex-direction: column;
     }
     .service__text{
       max-width: 500px;
       position: absolute;
       top: 85%;
       left: 50%;
       transform: translate(-50%, -50%);
     }
   }
   @media only screen and (max-width: 700px){
     .service__text{
       padding-left: 30px;
       padding-right: 30px;
       padding-bottom: 30px;
       max-width: 350px;
       max-height: 400px;
       position: absolute;
       top: 85%;
       left: 50%;
       transform: translate(-50%, -50%);
     }
   }
   
   /*end service section style*/
   .service__text2{
     position: absolute;
     top: 40px;
     left: 16px;
     width: 100%;
     max-width: 700px;
     padding-top: 45px;
     padding-bottom: 45px;
     padding-left: 35px;
     background: linear-gradient(rgba(255, 255, 255, 0.884),rgba(252, 252, 252, 0.979));
     box-shadow: 0 -5px 5px -5px rgb(81, 81, 81), 
                 0 5px 5px -5px rgb(81, 81, 81);
   }
   .service__text2 h3{
     padding-top: 20px;
     font-weight: 200;
     color: #212135;
   }
   .service__text2 p{
     padding-top: 10px;
     color: gray;
     padding-right: 10px;
   }
   @media only screen and (max-width: 995px) {
     .service__text2{
       max-width: 500px;
       position: absolute;
       top: 10%;
       left: 50%;
       transform: translate(-50%, -50%);
     }
   }
   @media only screen and (max-width: 700px){
     .service__text2{
       padding-left: 30px;
       padding-right: 30px;
       padding-bottom: 30px;
       max-width: 350px;
       max-height: 400px;
       position: absolute;
       top: 10%;
       left: 50%;
       transform: translate(-50%, -50%);
     }
     .service__back h2{
      
      padding-top: 50px;
     }
   }
   