.whatsapp__cover{
    padding-top: 20vh;
    padding-bottom: 15vh;
    background: linear-gradient(rgba(139, 69, 19, 0.5),rgba(139, 69, 19, 0.5)),
    url('https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80') no-repeat center / cover;
  }
  .whatsapp__cover .img{
    max-width: 200px;
    margin: 15px auto;
  }
  .whatsapp__cover .img img{
    margin-left: -8px;
  }
  .whatsapp{
    display: flex;
    width: 100%;
    height: auto;
    padding: 90px;
    align-items: center;
  }
  
  .whatsapp__text{
    width: 100%;
    max-width: 1000px;
    color: white;
    letter-spacing: 1px;
    padding-right: 50px;
  }
  .whatsapp__text h2{
    color: white;
    padding-bottom: 20px;
    font-size: 35px;
  }
  .whatsapp__text p{
    color: white;
    font-size: 17px;
    font-weight: 100;
    line-height: 23px;
  }
  .whatsApp__button{
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    color: white;
  }
  .whatsApp__button a{
    align-items: center;
  }
  .whatsApp__button span{
    font-size: 17.5px;
    font-weight: 400;
    color: black;
  }
  .btn{
    background-color: rgb(240, 240, 60);
    margin-top: 26px;
  }
  .whatsApp__icon{
    color: white !important;
  }
  @media only screen and (max-width: 995px){
    .whatsapp{
      flex-direction: column;
      text-align: center;
    }
    .whatsapp__text{
      padding-right: 0px;
    }
  }